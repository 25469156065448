<template>
  <div>
    <app-card>
      <template #body>
        <ticket-type-form @change="form = $event"/>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'ticketTypesList'})">
        {{ $t('btn.back_to_list') }}
      </b-button>
      <b-button variant="success" @click="create">
        {{ $t('btn.create') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import TicketTypeForm from './components/TicketTypeForm';

export default {
  components: {
    AppCard,
    TicketTypeForm
  },
  data: () => ({
    form: {}
  }),
  methods: {
    create() {
      this.$store.dispatch('ticketTypesStore/CREATE', this.form).then(() => {
        this.$router.push({name: 'ticketTypesList'});
      });
    }
  }
}
</script>