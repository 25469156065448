<template>
  <div>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.name')"
            v-model="form.name"
            :placeholder="$t('placeholder.enter_name')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppInput from '@/components/form-groups/AppInput';

export default {
  components: {
    AppInput
  },
  props: {
    type: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    form: {
      name: ''
    }
  }),
  watch: {
    type(data) {
      this.form.name = data.name;
    },
    form: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    }
  }
}
</script>